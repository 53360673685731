import { ClaimData } from "../types/claim-data";
import { isValidClaim } from "./isValidClaim";

export function canAccess(
  requiredClaims: ClaimData[],
  actualClaims: ClaimData[],
): boolean {
  if (!requiredClaims || !actualClaims) {
    throw new Error(
      "Argument(s) cannot be null or undefined: requiredClaims or actualClaims",
    );
  }
  if (requiredClaims.length === 0) {
    return true;
  }
  if (actualClaims.length === 0) {
    return false;
  }
  if (!requiredClaims.every((claim) => isValidClaim(claim))) {
    return false;
  }
  if (!actualClaims.every((claim) => isValidClaim(claim))) {
    return false;
  }
  let result = false;
  requiredClaims.forEach((requiredClaim) => {
    const matchingActualClaim = actualClaims.find(
      (actualClaim) => actualClaim.name === requiredClaim.name,
    );
    if (matchingActualClaim) {
      requiredClaim.value.forEach((requiredClaimValue) => {
        let matchingActualClaimValue = null;
        if (requiredClaimValue.toLowerCase() === "read") {
          matchingActualClaimValue = matchingActualClaim.value.find(
            (actualClaimValue) =>
              actualClaimValue.toLowerCase() === "read" ||
              actualClaimValue.toLowerCase() === "write",
          );
        } else {
          matchingActualClaimValue = matchingActualClaim.value.find(
            (actualClaimValue) => actualClaimValue.toLowerCase() === "write",
          );
        }
        if (matchingActualClaimValue) {
          result = true;
        }
      });
    }
  });
  return result;
}
