/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	CanActivateFn,
	Router,
} from "@angular/router";
import { ClaimsService } from "../services/claims/claims.service";
import { canAccess } from "./can-access";
import { map } from "rxjs";
import { ClaimData } from "../types/claim-data";

export const authorisationGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

	if (route.data["allowAnonymousAccess"] === true) {
		return true;
	}

	//TODO: Change to use constructor
	const router = inject(Router);
	const claimsService = inject(ClaimsService);

	return claimsService.claims$.pipe(
		map((claims: ClaimData[]) => {
			if (canAccess(route.data["requiredClaims"], claims)) {
				return true;
			} else {
				return router.parseUrl("/clients");
			}
		}),
	);
};
